<template>
  <div class="expande-horizontal fonte pt-3 column">
    <div
      class="ml-2"
      style="display: flex; min-height: 40px; align-items: center;"
    >
      <label
        style="cursor: pointer; z-index: 10; display: flex; width: 100%;"
        for="input-file2"
      >
        <v-flex xs12>
          <v-list color="transparent">
            <v-list-item
              @click="setCurrentDocumentUpload(document, index)"
              style="margin-bottom: 6px; border-radius: 3px; background-color: #fff;"
              :key="document.name"
              v-for="(document, index) in items"
            >
              <v-list-item-content>
                <v-list-item-title>{{ document.name }}</v-list-item-title>
                <v-list-item-title>
                  <v-progress-linear
                    v-if="
                      initialized && index === current_document_upload.index
                    "
                    height="6"
                    :value="loading"
                    :indeterminate="initialized && !loading"
                    style="border-radius: 5px;"
                    :color="$theme.primary"
                  ></v-progress-linear>
                </v-list-item-title>
                <v-list-item-subtitle v-if="value[index]">
                  <a
                    :href="value[index].link"
                    target="_blank"
                    style="text-decoration: none;"
                  >
                    Carregado, clique para ver
                  </a>
                </v-list-item-subtitle>
                <v-list-item-subtitle v-else>
                  Clique aqui para enviar
                </v-list-item-subtitle>
              </v-list-item-content>
              <v-list-item-action>
                <v-avatar color="#f2f2f2">
                  <v-icon color="green" v-if="value[index]">mdi-check</v-icon>
                  <v-icon color="orange" v-else>mdi-text-box-plus</v-icon>
                </v-avatar>
              </v-list-item-action>
            </v-list-item>
          </v-list>
        </v-flex>
      </label>
      <input
        style="cursor: pointer;"
        v-if="!initialized"
        id="input-file2"
        type="file"
        ref="input"
        accept="image/*,application/pdf"
        name="importxml"
        @change="upload"
      />
      <span id="file-name2"></span>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import {
  getStorage,
  ref,
  getDownloadURL,
  uploadBytesResumable,
  deleteObject
} from "firebase/storage";
export default {
  name: "DocumentFiles",
  props: {
    value: {
      type: Array,
      default: () => [],
      require: true
    },
    items: {
      type: Array,
      default: () => [],
      require: true
    },
    basePath: {
      type: String,
      required: true
    }
  },
  computed: {
    ...mapGetters(["getLoggedUser"])
  },
  data() {
    return {
      loading: 0,
      initialized: false,
      newFileName: "",
      current_document_upload: {}
    };
  },
  methods: {
    ...mapActions(["createConfirmAction"]),
    setCurrentDocumentUpload(doc, index) {
      if (this.initialized) return;
      this.current_document_upload = doc;
      this.current_document_upload.index = index;
    },
    upload(evt) {
      if (this.initialized) {
        this.resetInput();
        return;
      }
      this.initialized = true;
      evt.stopPropagation();
      evt.preventDefault();
      const acceptedDocs = ["jpg", "jpeg", "png", "pdf"];
      var file = evt.target.files[0];
      if (!file) return;
      var metadata = {
        contentType: file.type
      };

      if (!acceptedDocs.includes(file.name.split(".").pop())) return;

      const self = this;

      const storage = getStorage();
      const storageRef = ref(storage);
      const imagesRef = ref(
        storageRef,
        `${self.basePath}/${self.value ? self.value.length : 0}-${file.name}`
      );
      const taskLoad = uploadBytesResumable(imagesRef, file, metadata);
      taskLoad.on(
        "state_changed",
        //get progress
        snapshot => {
          self.loading =
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        },
        error => {},
        //success
        () => {
          getDownloadURL(taskLoad.snapshot.ref).then(link => {
            let documents = self.value;
            documents[self.current_document_upload.index] = {
              name: self.current_document_upload.name,
              index: self.current_document_upload.index,
              required: self.current_document_upload.required,
              format: file.name.split(".").pop(),
              size: file.size,
              link
            };
            self.$emit("input", documents);
            // self.$emit("input", [
            //   ...(self.value || []),
            //   {
            //     name: self.current_document_upload.name,
            //     index: self.current_document_upload.index,
            //     link,
            //     format: file.name.split(".").pop(),
            //     size: file.size
            //   }
            // ]);
            self.loading = 0;
            setTimeout(() => {
              self.initialized = false;
              self.current_document_upload = {};
            }, 1000);
          });
        }
      );
    },
    resetInput() {
      this.initialized = false;
      this.loading = 0;
      this.$refs.input.value = "";
    },
    removeFile(file) {
      const self = this;
      const storage = getStorage();
      const httpsReference = ref(storage, file.link);
      deleteObject(httpsReference).then(function() {
        let newFiles = self.value.filter(f => f.link !== file.link);
        self.$emit("input", newFiles);
        self.loading = 0;
        self.initialized = false;
      });
      // .catch(function(error) {
      // });
    },
    activeFileEdit(file) {
      const files = this.value || [];
      let newFiles = files.map(f => {
        if (f.link === file.link) {
          f.edit = true;
          this.newFileName = f.name;
        }
        return f;
      });
      this.$emit("input", newFiles);
    },
    saveFileEdit(file) {
      const files = this.value || [];
      let newFiles = files.map(f => {
        if (f.link === file.link) {
          f.name = this.newFileName;
          delete f.edit;
        }
        return f;
      });
      this.$emit("input", newFiles);
    }
  }
};
</script>

<style>
input[type="file"] {
  display: none;
}

.input-wrapper2 label:hover {
  background-color: #e1673c;
}

.img {
  border-radius: 6px;
  border: 2px solid rgb(71, 73, 71);
}
</style>
