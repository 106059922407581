<template>
  <div class="expande-horizontal pa-3 wrap centraliza column" style="min-height: 36vh;">
    <!-- <img style="width: 100px;" src="img/empty.gif" alt="empty" /> -->
    <v-avatar size="100" color="#333">
      <v-icon size="80" color="#f2f2f2">mdi-link</v-icon>
    </v-avatar>
    <span style="font-size: 2rem;" class="px-3 mt-12 fonte-bold text-center">
      Nenhuma empresa encontrada!
    </span>
    <span style="font-size: 12pt;" class="px-3 mt-3 grey--text fonte text-center">
      Copie seu link empresarial na tela inicial e cadastre empresas agora mesmo.
    </span>

  </div>
</template>

<script>
export default {
  props: {
    iniciarCadastro: {
      type: Function,
      default: () => {}
    }
  },
  data() {
    return {};
  }
};
</script>
